import React from 'react';
import { map, flatMap } from 'lodash';

import { Card, CardBody } from 'reactstrap';
import { Icon, IconStack } from 'js/components';

import MostContentious from './MostContentious';
import WorstResults from './WorstResults';
import TopResults from './TopResults';
import { ReactAutoMosaic } from 'react-auto-mosaic';

const AssessmentReviewDetailOverview = ({
  assessment,
  dataMap,
  filter,
  cohorts,
  participantCohortMap,
  legendFields,
  activeCohort,
  pinnedCohort,
  peekedCohort,
  onPinCohort,
  onClearPinnedCohort,
  onPeekCohort,
  onClearPeekedCohort,
}) => {
  if (!assessment) {
    return null;
  }

  const questions = flatMap(
    dataMap.assessments[assessment.id]?.questionSets ?? [],
    (qs, questionSetKey) => {
      return map(
        qs.questions,
        ({ normalizedScore, normalizedVariance }, questionKey) => {
          return {
            questionSetKey,
            questionKey,
            normalizedScore,
            normalizedVariance,
            questionSetDisplayName: qs.displayName,
          };
        }
      ).filter((x) => x.normalizedScore !== null);
    },
    []
  );

  const resultsComponentProps = {
    assessment,
    questions,
    filter,
    participantCohortMap,
    cohorts,
    activeCohort,
    legendFields,
    pinnedCohort,
    peekedCohort,
    onPinCohort,
    onClearPinnedCohort,
    onPeekCohort,
    onClearPeekedCohort,
  };

  return (
    <div className="content survey-review-detail">
      {questions.length > 0 ? (
        <ReactAutoMosaic
          gridColumns={12}
          gridGutterWidth={10}
          gridBreakpoints={{
            1920: 12,
            6000: 6,
          }}
        >
          <TopResults {...resultsComponentProps} />
          <WorstResults {...resultsComponentProps} />
          <MostContentious {...resultsComponentProps} />
        </ReactAutoMosaic>
      ) : (
        <Card>
          <CardBody className="media text-muted">
            <IconStack className="launch-icon mr-3" size="4x" fixedWidth>
              <Icon icon="question" />
              <Icon
                icon="question"
                transform="down-5 left-8 shrink-6 rotate-352"
              />
              <Icon
                icon="question"
                transform="down-5 right-8 shrink-6 rotate-10"
              />
            </IconStack>
            <div className="media-body">
              <h2>
                You might be putting the cart before the horse here&hellip;
              </h2>
              <p>
                It doesn't look like anyone has responded yet. Send (or resend)
                some invitations and let's get that data rolling in!
              </p>
            </div>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default AssessmentReviewDetailOverview;
