import { soThatAnswers, wowAnswers } from './base-answers';

export const scopeManagement = [
  {
    name: 'Scope Management',
    key: 'scope-management',
    category: 'scope-management',
    questions: [
      {
        text: "Before and issue is added to a sprint, it's pointed",
        key: 'pointed-wow',
        answers: wowAnswers,
      },
      {
        text: "The Team Lead and PO approve of work added or removed from a sprint before it's added or removed",
        key: 'work-approval-wow',
        answers: wowAnswers,
      },
    ],
  },
];
