import React, { useMemo, useCallback, useState } from 'react';
import { every, map } from 'lodash';
import { questionSetScoreDistance } from 'js/utils/assessment-utils';
import { lookupNameInDictionary } from 'js/utils/template';
import { scaleColorTraffic as color } from 'js/utils/color';
import { isScoredType } from 'js/utils/question-set';

import RecommendationResultsDistribution from './RecommendationResultsDistribution';
import { Icon } from 'js/components';
import { UncontrolledCollapse } from 'reactstrap';

const NodeRecommendation = ({
  node,
  templateDictionary,
  questionSetKey = null,
  showDistribution = false,
}) => {
  const key = questionSetKey === null ? 'question_set_key' : 'question_key';
  const toggleKey = useCallback(
    (qsScore) => {
      return `score-${node.data.id}-${qsScore.question_set_key.replaceAll(
        ' ',
        '-'
      )}-toggle`;
    },
    [node]
  );

  const scores =
    questionSetKey === null
      ? node.data.scores
      : node.data.scores[questionSetKey]?.questions;

  const lookupName = useMemo(() => {
    return questionSetKey !== null
      ? (nameKey) =>
          lookupNameInDictionary(templateDictionary, questionSetKey, nameKey)
      : (nameKey) => lookupNameInDictionary(templateDictionary, nameKey);
  }, [templateDictionary, questionSetKey]);

  const sortedScores = useMemo(
    () => map(scores).sort((a, b) => b.score - a.score),
    [scores]
  );

  const topRecommendations = useMemo(() => {
    return map(scores, (qss) => ({
      ...qss,
      distance: questionSetScoreDistance(qss.question_set_key, qss.score),
    }))
      .filter((qss) => !isNaN(qss.distance) && qss.score !== 1)
      .sort((a, b) => b.distance - a.distance)
      .slice(0, 5);
  }, [scores]);

  const isTopRecommendation = useCallback(
    (questionSetKey) => {
      return topRecommendations.some(
        (t) => t.question_set_key === questionSetKey
      );
    },
    [topRecommendations]
  );

  const [questionSetToggles, setQuestionSetToggles] = useState({});
  const handleToggle = (qsScore) => {
    setQuestionSetToggles({
      ...questionSetToggles,
      [qsScore.question_set_key]: !(
        questionSetToggles[qsScore.question_set_key] ?? false
      ),
    });
  };

  const toggleIconFor = useCallback(
    (qsScore) => {
      return questionSetToggles[qsScore.question_set_key]
        ? 'caret-down'
        : 'caret-right';
    },
    [questionSetToggles]
  );

  const renderScore = useCallback(
    (score) => {
      const hasOnlyUnscoredQuestions = every(
        score.questions,
        (q) => !isScoredType(q.question_type)
      );
      return (
        <>
          {hasOnlyUnscoredQuestions ? (
            <div className="score no-scored-results">No scored results</div>
          ) : (
            <div
              className="score"
              style={{
                width: `${Math.max(score.score * 100, 1)}%`,
                backgroundColor: color(score.score),
              }}
            ></div>
          )}
          {!questionSetKey && (
            <UncontrolledCollapse toggler={`#${toggleKey(score)}`}>
              <div className="mb-4 sub-scores">
                {Object.values(score.questions)
                  .sort((a, b) => a.score - b.score)
                  .map((qScore) => {
                    const isScoredQuestionType = isScoredType(
                      qScore.question_type
                    );

                    return (
                      <div
                        className={`pt-2 ${
                          isScoredQuestionType ? '' : 'unscored'
                        }`}
                        key={qScore.question_key}
                        title={
                          isScoredQuestionType
                            ? null
                            : 'Unscored - This question does not count toward the overall assessment score'
                        }
                      >
                        <div>
                          <span>
                            {lookupNameInDictionary(
                              templateDictionary,
                              score.question_set_key,
                              qScore.question_key
                            )}
                            {isScoredQuestionType ? null : (
                              <span className="unscored-label">
                                {' '}
                                (unscored)
                              </span>
                            )}
                          </span>
                        </div>
                        <div
                          className="score"
                          style={{
                            width: `${Math.max(qScore.unScore * 100, 1)}%`,
                            backgroundColor: color(qScore.unScore),
                          }}
                        ></div>
                      </div>
                    );
                  })}
              </div>
            </UncontrolledCollapse>
          )}
        </>
      );
    },
    [questionSetKey, toggleKey, templateDictionary]
  );

  return sortedScores.length === 0 ? (
    <div className="org-data">
      <div className="no-data muted">No data available</div>
    </div>
  ) : (
    <div className="org-data">
      <table>
        {showDistribution ? (
          <thead>
            <tr>
              <th />
              <th>
                <div className="recommendation-composite">
                  <div>Overall score</div>
                  <div>Contributing Scores</div>
                </div>
              </th>
            </tr>
          </thead>
        ) : null}
        <tbody>
          {sortedScores.map((x) => {
            return (
              <tr key={x[key]}>
                <td
                  className={`pr-2 scored-name ${
                    isTopRecommendation(x[key]) ? 'top' : ''
                  }`}
                >
                  {!questionSetKey ? (
                    <button
                      className="btn btn-link m-0"
                      id={toggleKey(x)}
                      onClick={() => handleToggle(x)}
                    >
                      {lookupName(x[key])}{' '}
                      <Icon fixedWidth icon={['fas', toggleIconFor(x)]} />
                    </button>
                  ) : (
                    lookupName(x[key])
                  )}
                </td>
                <td>
                  {showDistribution ? (
                    <div className="recommendation-composite">
                      <div>{renderScore(x)}</div>
                      <div>
                        <RecommendationResultsDistribution
                          node={node}
                          questionSetKey={x.question_set_key ?? questionSetKey}
                          questionKey={x.question_key}
                        />
                      </div>
                    </div>
                  ) : (
                    renderScore(x)
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default NodeRecommendation;
