import React from 'react';

import LongTextResults from './LongTextResults';
import LikertResults from './LikertResults';
import NullResults from './NullResults';
import UnscoredLikertResults from './UnscoredLikertResults';

const getComponent = (question) => {
  switch (question.type) {
    case 'likert':
      return LikertResults;

    case 'unscored-likert':
      return UnscoredLikertResults;

    case 'long-text':
      return LongTextResults;

    default:
      return NullResults;
  }
};

const QuestionResults = (props) => {
  const ResultsComponent = getComponent(props.question);

  return <ResultsComponent {...props} />;
};

export default QuestionResults;
