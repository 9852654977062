import { likertQuestionSets } from './question-sets/likert';
import { mixedQuestionSets } from './question-sets/mixed';
import { longTextQuestionSets } from './question-sets/long-text';
import { unscoredLikertQuestionSets } from './question-sets/unscored-likert';

export const sketchCapabilities = {
  name: 'Question Types',
  key: 'question-types',
  question_sets: [...mixedQuestionSets, ...likertQuestionSets, ...longTextQuestionSets, ...unscoredLikertQuestionSets],
};

export default sketchCapabilities;
