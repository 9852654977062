import { soThatAnswers, wowAnswers } from './base-answers';

export const sprintPractices = [
  {
    name: 'Daily Sprint Practices',
    key: 'daily-sprint-practices',
    category: 'sprint-practices',
    questions: [
      // {
      //   text: 'Work in Progress (WIP) limits are used on board columns',
      //   key: 'wip-wow',
      //   answers: wowAnswers,
      // },
      {
        text: 'Daily Scrum is daily (or as needed), same time and place',
        key: 'ds-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team reviews sprint progress and scope changes at the start of standup (ex. Insights or Burnup Chart)',
        key: 'review-sprint—progress-wow',
        answers: wowAnswers,
      },
      {
        text: 'JIRA issues ‘comments’ are updated by the issue assignees with actions taken and relevant info regularly',
        key: 'updates-wow',
        answers: wowAnswers,
      },
      {
        text: 'Team delivers work to QA early and often in the sprint',
        key: 'deliver—to-qa-wow',
        answers: wowAnswers,
      },
      {
        text: 'QA consults with the team to determine if a bug is needed',
        key: 'qa—consults-wow',
        answers: wowAnswers,
      },
      {
        text: 'Completed Done work is pointed',
        key: 'done—work-wow',
        answers: wowAnswers,
      },
      {
        text: 'All core team members attend the daily scrum meeting',
        key: 'attendance-wow',
        answers: wowAnswers,
      },
    ],
  },
];
