import React from 'react';

import LikertQuestion from './LikertQuestion';
import LongTextQuestion from './LongTextQuestion';
import NullQuestion from './NullQuestion';

const getComponent = (question) => {
  switch (question.type) {
    case 'likert':
    case 'unscored-likert':
      return LikertQuestion;

    case 'long-text':
      return LongTextQuestion;

    default:
      return NullQuestion;
  }
};

const Question = (props) => {
  const QuestionComponent = getComponent(props.question);

  return <QuestionComponent {...props} />;
};

export default Question;
