export function titleCase(s) {
  if (s === undefined || s === null || s === '') {
    return s;
  }

  return s.replace(/\b\w+/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
}

export function monogram(s, minLength = 0, maxLength = 3) {
  if (minLength > maxLength) {
    throw new Error(
      `Minimum length > maximum length (${minLength} > ${maxLength})`
    );
  }

  const matches = s.match(/\b\w+/g);
  const matchCount = matches.length;

  if (matchCount < minLength) {
    let diff = minLength - matchCount;

    return matches
      .map(x => {
        if (diff > 0) {
          const result = x.substr(0, diff + 1);
          diff -= result.length - 1;

          return result;
        }

        return x.charAt(0);
      })
      .join('');
  } else {
    return matches
      .map(x => x.charAt(0))
      .slice(0, Math.min(matchCount, maxLength))
      .join('');
  }
}
