import { soThatAnswers, wowAnswers } from './base-answers';

export const planning = [
  {
    name: 'Planning',
    key: 'planning',
    category: 'planning',
    questions: [
      {
        text: 'We have a clearly defined product owner (PO)',
        key: 'po-definition-wow',
        answers: wowAnswers,
      },
      {
        text: 'Product owner actively shares the roadmap',
        key: 'po-roadmap-wow',
        answers: wowAnswers,
      },
      {
        text: 'Product owner actively shares performance KPIs',
        key: 'po-KPI-wow',
        answers: wowAnswers,
      },
      {
        text: 'Product owner actively shares experimentation results',
        key: 'po-experimentation-wow',
        answers: wowAnswers,
      },
      {
        text: 'Product owner has a prioritized Product Backlog',
        key: 'po-pbl-wow',
        answers: wowAnswers,
      },
      // {
      //   text: 'Product owner prioritizes backlog based on WSJF or similar approach',
      //   key: 'po-prioritizes-wow',
      //   answers: wowAnswers,
      // },
      // {
      //   text: 'Team has a sprint backlog',
      //   key: 'team—sprint-backlog-wow',
      //   answers: wowAnswers,
      // },
      {
        text: 'Team maintains a defined two-sprint backlog',
        key: 'team—2-sprint-backlog-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team has regular sprint planning meetings',
        key: 'team—sprint-planning-wow',
        answers: wowAnswers,
      },
      {
        text: 'Sprint velocity is used as a guide in planning',
        key: 'team—sprint-velocity-wow',
        answers: wowAnswers,
      },
      {
        text: 'Past work category measures are used as guides in planning',
        key: 'past—work-category-wow',
        answers: wowAnswers,
      },
      {
        text: 'Percent Work Completed is used as a guide in planning',
        key: 'percent-work-completed',
        answers: wowAnswers,
      },
      {
        text: 'Appropriate planned sprint work is pointed',
        key: 'work—pointed-wow',
        answers: wowAnswers,
      },
      {
        text: 'All planned sprint work has a Work Category',
        key: 'work-category-exists-wow',
        answers: wowAnswers,
      },
      {
        text: 'All work for DPQA has a DPQA label',
        key: 'dpqa-label-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team commits to planned work',
        key: 'team-commit-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team sets aside and plans capacity for growth experiments',
        key: 'growth-experiments-wow',
        answers: wowAnswers,
      },
      {
        text: 'The team has and follows a defined release plan/cadence',
        key: 'release-plan-wow',
        answers: wowAnswers,
      },
      {
        text: 'UX research is the first phase of any new feature',
        key: 'ux-research-wow',
        answers: wowAnswers,
      },
      {
        text: 'UX Designer leads design intake of new features',
        key: 'ux-designer-wow',
        answers: wowAnswers,
      },
      {
        text: 'The stakeholder requests changes, new features, etc directly from the product owner',
        key: 'stakeholder-requests-wow',
        answers: wowAnswers,
      },
      {
        text: "Product owner includes analytics in acceptance criteria as part of the feature's MVP",
        key: 'include-analytics-wow',
        answers: wowAnswers,
      },
      {
        text: 'The squad plans capacity to address accessibility findings',
        key: 'capacity-for-accessibility',
        answers: wowAnswers,
      },
      {
        text: 'The team has an agreed upon documented Definition of Done',
        key: 'definition—of-done-wow',
        answers: wowAnswers,
      },
    ],
  },
];
