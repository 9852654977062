import standardCohortKeys from 'js/utils/cohort-keys';
import {
  keyBy,
  map,
  mapValues,
  mean,
  mergeWith,
  pickBy,
  reduce,
  isObject,
  forEach,
} from 'lodash';
import { titleCase } from 'js/utils/string';

function normalizeScore(value, range) {
  const max = Math.max(...range);
  const min = Math.min(...range);
  return min === max ? 1 : (value - min) / (max - min);
}

export function isScorable(question) {
  return isScoredType(question.type);
}
export function isScoredType(questionType) {
  switch (questionType) {
    case 'long-text':
    case 'unscored-likert':
      return false;

    default:
      return true;
  }
}

export const NA = '__na__';

export function getQuestionSetCategory(questionSet) {
  return questionSet.category || 'uncategorized';
}

export const getAnswerDomain = (answers) => {
  if (answers.length === 0) {
    return [0, 0];
  }

  const initialBound = +answers[0].value;

  return answers.reduce(
    (accumulator, answer) => {
      if (answer.is_na) {
        return accumulator;
      }
      const answerValue = +answer.value;

      return [
        Math.min(accumulator[0], answerValue),
        Math.max(accumulator[1], answerValue),
      ];
    },
    [initialBound, initialBound]
  );
};

const calculateQuestionSetMetrics = (
  questionSetQuestions,
  participantCohortMap,
  filter,
  assessmentInsertedAt
) => {
  const applyCohortFilter = Object.keys(filter?.cohorts ?? {}).length > 0;

  return keyBy(
    questionSetQuestions.map((q) => {
      const answerDomain = getAnswerDomain(q.answers);
      const [minScore, maxScore] = answerDomain;
      const normalizeScore =
        maxScore - minScore !== 0
          ? (v) => (v - minScore) / (maxScore - minScore)
          : (v) => v;
      const scoreMapper = isScorable(q)
        ? (a) => normalizeScore(+a.value)
        : (a) => null;
      const answers = q.answers.map(({ id, ...a }) => ({
        ...a,
        normalizedScore: scoreMapper(a),
      }));

      const baseMetrics = {
        id: q.id,
        key: q.key,
        name: q.name,
        text: q.text,
        type: q.type,
        lastResponseTime: null,
        assessmentInsertedAt,
        averageScore: null,
        meanScore: null,
        normalizedScore: null,
        normalizedVariance: null,
        responseCount: 0,
        naCount: 0,
        responseTotals: {},
        answerDomain: getAnswerDomain(q.answers),
        answers,
      };

      if (q.responses.length === 0) {
        return baseMetrics;
      }

      const responseMetrics = (q.responses || []).reduce(
        (acc, response) => {
          const cohort = participantCohortMap[response.participant_id];
          const includeResponse = (filter?.cohorts ?? {})[cohort];
          return applyCohortFilter && !includeResponse
            ? acc
            : {
                ...acc,
                lastResponseTime: Math.max(
                  response.inserted_at,
                  acc.lastResponseTime
                ),
                responseCount: response.is_na
                  ? acc.responseCount
                  : acc.responseCount + 1,
                naCount: response.is_na ? acc.naCount + 1 : acc.naCount,
                responseTotals: {
                  ...acc.responseTotals,
                  [response.is_na ? NA : response.value]:
                    (acc.responseTotals[response.is_na ? NA : response.value] ||
                      0) + 1,
                },
                totalScore: response.is_na
                  ? acc.totalScore
                  : acc.totalScore + +response.value,
              };
        },
        {
          lastResponseTime: null,
          responseCount: 0,
          naCount: 0,
          totalScore: 0,
          responseTotals: q.answers.reduce(
            (acc, answer) => ({
              ...acc,
              [answer.is_na ? NA : answer.value]: 0,
            }),
            {}
          ),
        }
      );

      const meanScore =
        responseMetrics.responseCount === 0 || !isScorable(q)
          ? null
          : responseMetrics.totalScore / responseMetrics.responseCount;
      const [minAnswerValue, maxAnswerValue] = baseMetrics.answerDomain;
      const normalizedScore =
        meanScore === null
          ? null
          : maxAnswerValue === minAnswerValue
          ? 1
          : (meanScore - minAnswerValue) / (maxAnswerValue - minAnswerValue);

      const variances = (q.responses || [])
        .map((r) => {
          if (r.is_na) {
            return null;
          } else if (minAnswerValue === maxAnswerValue) {
            return 0;
          }

          const val = +r.value;
          const normalizedVal =
            (val - minAnswerValue) / (maxAnswerValue - minAnswerValue);
          return Math.pow(normalizedVal - normalizedScore, 2);
        })
        .filter((x) => x !== null);

      const normalizedVariance =
        variances.length === 0 ? null : mean(variances);

      return {
        ...baseMetrics,
        lastResponseTime: responseMetrics.lastResponseTime,
        responseCount: responseMetrics.responseCount,
        naCount: responseMetrics.naCount,
        responseTotals: responseMetrics.responseTotals,
        averageScore: meanScore,
        meanScore: meanScore,
        normalizedScore,
        normalizedVariance: isNaN(normalizedScore) ? null : normalizedVariance,
      };
    }),
    'key'
  );
};

const mergeQuestionData = (currentData, questionData) => {
  return mergeWith(currentData, questionData, (orig, source) => {
    if (source === undefined) {
      return orig;
    } else if (orig === undefined) {
      return source;
    }

    if (
      orig.assessmentInsertedAt > source.assessmentInsertedAt &&
      orig.responseCount + orig.naCount > 0
    ) {
      return orig;
    }

    return source;
  });
};

export const getSummaryMetrics = (assessments, options) => {
  options = {
    filter: null,
    ...options,
  };

  let configCategories = {};

  // find all of the categories
  // find all the question sets
  // find all of the questions
  const dataMap = {
    categories: {},
    questionSets: {},
    assessments: {},
  };

  // for a category, remember all of the question sets it contains
  // for a question set (key), remember the question set it belongs to
  // for a question set (key), remember all of the questions it has ever had
  // for a question set (key), remember the last time it has been asked
  // for a question, remember the last time it has been asked
  // for a question, remember the aggregate score from the last time it was asked
  // TODO: for a question, remember last sets of possible answers (most recent are index 0)

  assessments.forEach((a) => {
    const assessmentCategories = a?.config?.categories ?? {};
    if (assessmentCategories !== undefined) {
      configCategories = {
        ...configCategories,
        ...mapValues(assessmentCategories, () => null),
      };
    }

    const participantCohortMap = a.participants.reduce((acc, p) => {
      acc[p.id] = p.cohort ?? standardCohortKeys.NULL;
      return acc;
    }, {});

    const assessmentInsertedAt = new Date(a.inserted_at);
    const assessmentMetrics = (dataMap.assessments[a.id] = {
      questionSets: {},
      insertedAt: assessmentInsertedAt,
    });

    (a.question_sets || []).forEach((qs, i) => {
      const questionSetMetrics = {
        lastResponseTime: null, // set later
        assessmentInsertedAt,
        normalizedScore: null, // set later
        normalizedVariance: null,
        displayName: qs.name,
        category: getQuestionSetCategory(qs),
        lastRun: qs.inserted_at,
        questions: calculateQuestionSetMetrics(
          qs.questions,
          participantCohortMap,
          options.filter,
          assessmentInsertedAt
        ),
      };
      questionSetMetrics.lastResponseTime = reduce(
        questionSetMetrics.questions,
        (acc, q) => {
          return q.lastResponseTime > acc ? q.lastResponseTime : acc;
        },
        null
      );

      const [totalScore, questionsAnswered] = reduce(
        questionSetMetrics.questions,
        (acc, q) =>
          q.meanScore === null ? acc : [acc[0] + q.normalizedScore, acc[1] + 1],
        [0, 0]
      );

      const normalizedQsScore =
        questionsAnswered === 0 ? null : totalScore / questionsAnswered;

      questionSetMetrics.normalizedScore = normalizedQsScore;

      const variances = map(questionSetMetrics.questions, (q) =>
        q.normalizedScore === null
          ? null
          : Math.pow(normalizedQsScore - q.normalizedScore, 2)
      ).filter((x) => x !== null);

      questionSetMetrics.normalizedVariance =
        normalizedQsScore === null || variances.length === 0
          ? null
          : mean(variances);

      assessmentMetrics.questionSets[qs.key] = questionSetMetrics;

      // TODO: something weird is going on here (setting this key multiple times)...need to investigate more
      const origQsData = dataMap.questionSets[qs.key] || {
        lastRun: null,
        displayName: qs.name,
      };

      const questions = mergeQuestionData(
        origQsData.questions,
        questionSetMetrics.questions
      );

      const qsData = (dataMap.questionSets[qs.key] = {
        // lastResponseTime,
        displayName: qs.name,
        key: qs.key,
        category: getQuestionSetCategory(qs),
        ...origQsData,
        lastRun:
          qs.inserted_at > origQsData.lastRun
            ? qs.inserted_at
            : origQsData.lastRun,
        questions,
        normalizedScore: normalizedQsScore,
        normalizedVariance: null, // calculate this after everything is compiled
      });

      dataMap.categories[qsData.category] = {
        ...dataMap.categories[qsData.category],
        [qs.key]: null,
      };
    });

    const assessmentMean = mean(
      map(assessmentMetrics.questionSets, 'normalizedScore').filter(
        (x) => x !== null
      )
    );

    assessmentMetrics.normalizedScore = isNaN(assessmentMean)
      ? null
      : assessmentMean;
  });

  dataMap.categories = pickBy(
    {
      ...mapValues(configCategories, (o, key) => dataMap.categories[key]),
      ...dataMap.categories,
    },
    (o) => o !== undefined
  );

  forEach(dataMap.questionSets, (qs) => {
    const variances = map(qs.questions, (q) =>
      q.normalizedScore === null
        ? null
        : Math.pow(qs.normalizedScore - q.normalizedScore, 2)
    ).filter((x) => x !== null);
    const normalizedVariance = variances.length === 0 ? null : mean(variances);

    qs.normalizedVariance = normalizedVariance;
  });

  return dataMap;
};

export const generateBaseParticipantMetrics = () => ({
  questionSets: {},
  naCount: 0,
  responseCount: 0,
  meanNormalizedScore: null,
});

export const getParticipantMetrics = (assessments, options) => {
  options = {
    filter: null,
    ...options,
  };

  return assessments.reduce((acc, a) => {
    const rawParticipantMetrics = {};

    a.question_sets.forEach((qs) => {
      qs.questions.forEach((q) => {
        if (isScorable(q)) {
          const answerDomain = getAnswerDomain(q.answers);
          q.responses.forEach((r) => {
            const participantId = r.participant_id;
            const isNa = r.is_na;
            const normalizedScore = isNa
              ? null
              : normalizeScore(r.value, answerDomain);

            const { questionSets } = (rawParticipantMetrics[participantId] =
              rawParticipantMetrics[participantId] ?? { questionSets: {} });
            const { questions } = (questionSets[qs.key] = questionSets[
              qs.key
            ] ?? { questions: {} });
            questions[q.key] = {
              isNa,
              normalizedScore,
              value: r.value,
            };
          });
        } else {
          // TODO: handle non-scorable questions
        }
      });
    });

    const assessment = mapValues(
      rawParticipantMetrics,
      (participantMetrics, participantId) => {
        const questionSets = (participantMetrics.questionSets = mapValues(
          participantMetrics.questionSets,
          (questionSetMetrics, questionSetKey) => {
            const { scoredResponses, naCount } = reduce(
              questionSetMetrics.questions,
              (acc, q) => {
                return {
                  ...acc,
                  scoredResponses:
                    q.normalizedScore === null
                      ? acc.scoredResponses
                      : [...acc.scoredResponses, q.normalizedScore],
                  naCount: q.isNa ? acc.naCount + 1 : acc.naCount,
                };
              },
              { scoredResponses: [], naCount: 0 }
            );

            return {
              ...questionSetMetrics,
              meanNormalizedScore: mean(scoredResponses),
              responseCount: scoredResponses.length,
              naCount: naCount,
            };
          }
        ));

        const { responseCount, naCount, scoredResponses } = reduce(
          questionSets,
          (acc, qs) => {
            return {
              scoredResponses: [...acc.scoredResponses, qs.meanNormalizedScore],
              responseCount: qs.responseCount + acc.responseCount,
              naCount: qs.naCount + acc.naCount,
            };
          },
          {
            scoredResponses: [],
            responseCount: 0,
            naCount: 0,
          }
        );

        return {
          questionSets,
          naCount,
          responseCount,
          meanNormalizedScore: mean(scoredResponses),
        };
      }
    );

    return { ...acc, [a.id]: assessment };
  }, {});
};

export const getCategoryLookups = (assessments, supplementalCategories) => {
  // NOTE: we could do this without the supplemental categories, but we'd have to do more calculations
  const configCategories = assessments
    .map((a) => {
      return a.config?.categories;
    })
    .filter(isObject)
    .reduce((acc, categories) => {
      return { ...acc, ...categories };
    }, {});

  const allCategories = mergeWith(
    configCategories,
    supplementalCategories,
    (obj, _src, key) =>
      obj !== undefined ? obj : { display_name: titleCase(key) }
  );

  const categoryMap = new Map(
    map(allCategories, (value, key) => [key, value]).sort(
      (a, b) => a[1]?.ordinal - b[1]?.ordinal
    )
  );

  let ci = 0;
  categoryMap.forEach((value) => {
    value.palette = `palette-cat-${++ci}`;
  });

  return categoryMap;
};
