import React, { useMemo } from 'react';

import GradedQuestionScore from './GradedQuestionScore';
import IsCompletedQuestionScore from './IsCompletedQuestionScore';
import NoResponse from './NoResponse';

function getQuestionComponent(question, response) {
  if (!response) {
    return NoResponse;
  }

  switch (question.type) {
    case 'long-text':
      return IsCompletedQuestionScore;

    case 'likert':
    default:
      return GradedQuestionScore;
  }
}

const QuestionScore = (props) => {
  const { question, participantId } = props;
  const { responses = [] } = question;
  const response = useMemo(
    () => responses.find((r) => r?.participant_id === participantId),
    [responses, participantId]
  );

  const QuestionComponent = getQuestionComponent(question, response);

  return <QuestionComponent response={response} {...props} />;
};

export default QuestionScore;
